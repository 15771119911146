import Vue from 'vue'
import axios from 'axios'
import { baseURL, requestTimeout, contentType } from '@/config'
import store from '@/store'
import qs from 'qs'

/** axios初始化 */
const instance = axios.create({
	baseURL,
	timeout: requestTimeout,
	headers: {
		'Content-Type': contentType
	}
})

/** axios请求拦截器 */
instance.interceptors.request.use(
	(config) => {

		config.data = qs.stringify(config.data)
		
		return config
	}, 
	(error) => {
		return Promise.reject(error)
	}
)

/** axios响应拦截器 */
instance.interceptors.response.use(
	(response) => {
		if (response.status === 401) {
			store.dispatch('setToken', '')
		}

		if (response.status === 200) {
			return response.data
		} else {
			if (response.data.message) {
				Vue.prototype.$message.error(response.data.message)
			}

			return Promise.resolve({ data: response, ...response })
		}
	},
	(error) => {
		return Promise.resolve(error.response)
	}
)

export default instance