import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css'
import VueLazyload from 'vue-lazyload'

// px转rem
import './utils/flexible'

import './styles/app.css'

Vue.use(ElementUI,VueLazyload)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
