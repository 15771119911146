import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/config/routes'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach( function (to, form, next) {
  next()
})

router.afterEach( function (to, from) {
  
})

export default router
