/**
 * 自动导入vuex模块
 */
import Vue from 'vue'
import Vuex from 'vuex'
import {fetchNavList} from "@/api/nav";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeIndex: 0, // 当前激活的导航项的索引
    navData: null, // 导航栏数据
  },
  mutations: {
    // 定义修改状态的方法
    setNavData(state, data) {
      state.navData = data
    },
    setActiveIndex(state, index) {
      state.activeIndex = index
    },
  },
  actions: {
    setActiveIndex({ commit }, index) {
      commit('setActiveIndex', index)
    },

    // 定义异步操作
    async asyncFetchNavData({ commit }) {
      const { data } = await fetchNavList()

      if (data) {
        const navData = data.map(item => ({
          title: item.name,
          uri: `/${item.alias}`
        }));

        navData.unshift({
          title: '首页',
          uri: '/'
        })

        commit('setNavData', navData); // 提交修改状态的方法
      }
    },
  },
})